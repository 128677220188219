import React, { useState, useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import { Input, TextField, Select, FormControl, InputLabel, MenuItem, Box, Button, Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const Params = ({ token, users, user, sites }) => {

console.log('Params user:', user, token);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // try {
    //   const response = await fetch('/api/auth', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ email:user.email, password:user.password }),
    //   });

    //   if (response.ok) {
    //     const data = await response.json();
    //     // console.log('Token 1:', data.token);
    //     setUser({...user,  isConnected:true, token:data.token, error:false });
    //     connexion(data.token);
    //   } else {
    //     console.error('Erreur de connexion');
    //     setUser({...user,  error:true});
    //   }
    // } catch (error) {
    //   console.error('Erreur:', error);
    // }

  };


  function handleChange(event) {
    console.log('handleChange:', event.target.value);
  }
  
  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        //justifyContent="center"
        minHeight="20vh"
      >
         <h2 style={{ textAlign: 'center' }}><span style={{color:'green'}}>Gecad - </span>Paramètres</h2>
        {/* <h2>Paramètres</h2> */}

        <label htmlFor="site">Site en cours:</label>
      <Select id="site"
      value={3}>
       {
        sites.map((s) => {
          return <MenuItem key={s.idSite} value={s.idSite}>{s.ville}</MenuItem>
        })
        }
      </Select>

      <FormControl sx={{ m: 1, minWidth: '60%' }}>
      {/* <InputLabel id="lMatieres">Matiere 88</InputLabel> */}
        <Autocomplete
          id="users"
          options={users}
          getOptionLabel={(option) => option.nom + " " + option.prenom}
          renderInput={(params) => <TextField {...params} label="Utilisateur" />}
          // onChange={(event, newValue) => {
          // if (newValue) {
            // setApport(prevApport => {
            // const unite = unités.find(u => u.idUnite === newValue.IDUnite);
            // return {
            //   ...prevApport,
            //   idMatiere: newValue.idMatiere,
            //   libMatiere: newValue.lib_matiere,
            //   idUnite: newValue.IDUnite,
            //   libUnite: unite ? unite.lib_unite : '',
            //   codeUnite: unite ? unite.code_unite : ''
            // };
            // });
            // Donner le focus à l'input de saisie de la quantité
            //  setTimeout(() => quantiteRef.current.focus(), 0);
          // }
          // }}
        />
      </FormControl>

    
       
      </Box>
    </Container>
  );
}

export default Params;