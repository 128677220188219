import React, { useState, useRef} from 'react';
import { BarcodeScanner } from 'react-barcode-scanner'
import 'react-barcode-scanner/polyfill'
 


const Lecture = () => {
  
    return (
      <BarcodeScanner />
    )
  }


export default Lecture;