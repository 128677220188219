// Code for the Site component
import React, { useState, useRef, useEffect} from 'react';
import { Input, TextField, Select, FormControl, InputLabel, MenuItem, Box, Button, OutlinedInput, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, CircularProgress } from '@mui/material';


const Site = ({api, setPage, site, setSite}) => {


const [idSite, setIdsite] = useState();
const [sites, setSites] = useState([]);
//const [site, setSite] = useState({});

useEffect(() => {
    getSites(api);
}
, [api]);

function getSites(api) {
    console.log('getSites', api + '/s');
    fetch(api + '/s', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        // console.log('response:', response);
        } else {
          console.error('Erreur de connexion');
        }
      })
      .then(data => {
        console.log('Data:', data);
        setSites(data) ;
      //  setIdsite(data.length);
      })
      .catch(error => {
        console.error('Erreur:', error);
        // setLoading(false);
      });
  }

  // Sélection du site dans la combo
const handleSiteChange = (event) => {
  const selectedSite = sites.find(s => s.idSite === event.target.value);
    setIdsite(selectedSite.idSite);
};

// Enregistrement du site sélectionné
function defineSite() {
    const selectedSite = sites.find(s => s.idSite === idSite);
    let finalSite  = {idSite: selectedSite.idSite, code: selectedSite.code_site, lib: selectedSite.ville};
    const base64Site = btoa(JSON.stringify(finalSite));
    setSite(finalSite);
    localStorage.setItem('site', base64Site);
    setTimeout(() => {
        console.log('Passage');
        setPage('Passage');
    }, 100);
}

return (
    <>
    <Box display="flex" flexDirection="column" alignItems="center" minHeight="20vh">
        <h2 style={{ textAlign: 'center' }}><span style={{color:'green'}}>Gecad - </span>Site</h2>
            
            <p>Définissez ici votre site de saisie</p>

        <Select
            id="site"
            label="Sélectionnez votre site"
            value={site.idSite}
            onChange={handleSiteChange}
            style={{ width: '50%' }}
        >

    {sites.map((s) => (
        <MenuItem key={s.idSite} value={s.idSite}>
            {s.ville}
        </MenuItem>
    ))}

</Select>
    {site.idSite && <Button variant="contained" color="primary" onClick={defineSite} style={{marginTop:'20px'}}>Enregistrer</Button>}
    <Button variant="contained" color="primary" onClick={defineSite} style={{marginTop:'20px'}}>Enregistrer</Button>
    </Box>
    </>
);

}

export default Site;
