// Liste des passages du jour
import React, { useState, useRef, useEffect} from 'react';
import { Input, TextField, Select, FormControl, InputLabel, MenuItem, Box, Button, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, CircularProgress } from '@mui/material';


const Liste = ({api, getSiteName, editionPassage}) => {

    const [passages, setPassages] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [loading, setLoading] = useState(true); // État de chargement

useEffect(() => {
    getPassages();
}, [api]);

function getPassages() {
    console.log('getPassages', api + '/passages');
    setLoading(true);
    fetch(api + '/passages', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Authorization': 'toto',
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        // console.log('response:', response);
        } else {
          console.error('Erreur de connexion');
        }
      })
      .then(data => {
        console.log('Data:', data);
        setPassages(data) ;
        setLoading(false);
      })
      .catch(error => {
        console.error('Erreur:', error);
        setLoading(false);
      });
  }
// Reprendre le passage sélectionné en édition 
function modPassage() {
    console.log('modPassage:', passages[selectedRow]);
    fetch(api + '/modPassage', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify(passages[selectedRow]),
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                console.error('Erreur de connexion');
            }
        })
        .then(data => {
            console.log('Data:', data);
        })
        .catch(error => {
            console.error('Erreur:', error);
        });
}

function formatDateTime(dateEntree, heureEntree) {
// Créer un objet Date à partir de la chaîne JSON
// const date = new Date(`${dateEntree}T${heureEntree}`);
const date = new Date(dateEntree.substring(0,10)+heureEntree.substring(10));
console.log('date:', date);

// Obtenir la différence en minutes entre l'heure locale et GMT
const timezoneOffset = date.getTimezoneOffset();
// Convertir cette différence en heures
const timezoneOffsetHours = timezoneOffset / 60;
console.log(`Timezone offset: ${timezoneOffsetHours} hours`);

date.setHours(date.getHours() + timezoneOffsetHours);

// Extraire le jour, le mois et l'année
const day = String(date.getDate()).padStart(2, '0');
const monthNames = ["Jan", "Fev", "Mars", "Avril", "Mai", "Juin", "Juil", "Auout", "Sept", "Oct", "Nov", "Dec"];
const month = monthNames[date.getMonth()]; // Les mois sont indexés à partir de 0
const year = String(date.getFullYear()).slice(-2); // Prendre les deux derniers chiffres de l'année

// Extraire les heures et minutes en tenant compte du fuseau horaire local
const hours = String(date.getHours()).padStart(2, '0');
const minutes = String(date.getMinutes()).padStart(2, '0');

console.log(`${hours}H${minutes}`); // Affiche l'heure correcte

return `${day} ${month} ${hours}:${minutes}`;
}

return (
    <>
        <h2><span style={{color:'green', paddingLeft:'15%'}}>Gecad - </span>Passages du jour</h2>   
        {loading && <CircularProgress />}

        {!loading && passages  &&
        <>
            <table>
                <thead>
                    <tr>
                        <th style={{ textAlign: 'center' }}>Badge</th>
                        <th style={{ textAlign: 'center' }}>Site</th>
                        <th style={{ textAlign: 'center' }}>Passage</th>
                        <th style={{ textAlign: 'center' }}>Raison sociale</th>
                    </tr>
                </thead>
                <tbody>
                    {passages.map((passage, index) => (
                        <tr 
                            key={index} 
                            onClick={() => setSelectedRow(index)} 
                            style={{ backgroundColor: selectedRow === index ? 'lime' : 'transparent' }}
                        >
                            <td style={{ textAlign: 'center' }}>{passage.numBadge}</td>
                            <td style={{ textAlign: 'center' }}>{getSiteName(passage.idSite)}</td>
                            <td style={{ textAlign: 'center' }}>{formatDateTime(passage.dateEntree, passage.heureEntree)}</td>
                            <td style={{ textAlign: 'center' }}>{passage.raisonSociale}</td>
                        </tr>
                    ))}
                </tbody>    
            </table>
             {selectedRow !== null  && <Button variant="contained" color="secondary" onClick={() => editionPassage(passages[selectedRow])} style={{marginTop:'10px'}}>Editer le passage</Button> }
             </>
        }
        { !passages && <p>Aucun passage saisi ce jour</p>}
        {selectedRow !== null && (
            <div>
                <h3>Détails des apports</h3>
                <table>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}>Matière</th>
                            <th style={{ textAlign: 'center', width:'20%'}}>Quantité</th>
                            <th style={{ textAlign: 'center', width:'20%' }}>Unité</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            passages[selectedRow].lines.map((apport, index) => (
                                <tr key={index}>
                                    <td style={{ textAlign: 'center' }}>{apport.libMatiere}</td>
                                    <td style={{ textAlign: 'center' }}>{apport.qteDechet}</td>
                                    <td style={{ textAlign: 'center' }}>{apport.codeUnite}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        )}
    </>
);

}

export default Liste;