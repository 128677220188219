import React from 'react';


// { toggleMenu, setPage }
const Infos = ({site, user, os, ua}) => {


    return (
         <>
       {/* <h2>Menu</h2> */}
      {/* <div style={{ width: '200px', backgroundColor: '#f0f0f0', height: '100%', position: 'fixed', top: 0, left: 0, zIndex:8, paddingLeft:5}}> */}
        {/* <h2 onClick={()=>toggleMenu()}>Informations</h2> */}
        {/* <hr /> */}
        <h2 style={{ textAlign: 'center' }}><span style={{color:'green'}}>Gecad - </span>Informations</h2>
      

        <table>
            <thead>
                <tr>
                    <th style={{ textAlign: 'center' }}>Information</th>
                    <th style={{ textAlign: 'center' }}>Valeur</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{ textAlign: 'center' }}>Utilisateur</td>
                    <td style={{ textAlign: 'center' }}>{user.nom} {user.prenom}</td>
                </tr>
                <tr>
                    <td style={{ textAlign: 'center' }}>Os</td>
                    <td style={{ textAlign: 'center' }}>{os}</td>
                </tr>
                <tr>
                    <td style={{ textAlign: 'center' }}>Navigateur</td>
                    <td style={{ textAlign: 'center' }}>{ua}</td>
                </tr>
                <tr>
                    <td style={{ textAlign: 'center' }}>Site</td>
                    <td style={{ textAlign: 'center' }}>{site.lib}</td>
                </tr>
                <tr>
                    <td style={{ textAlign: 'center' }}>Société</td>
                    <td style={{ textAlign: 'center' }}>{site.soc}</td>
                </tr>
            </tbody>
        </table>







        {/* <h2>informations</h2> */}
        {/* <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li style={{ textAlign: 'left', padding: '8px 0' }} onClick={() => { setPage('Params'); toggleMenu(); }}>Paramètres</li>
          <li style={{ textAlign: 'left', padding: '8px 0' }} onClick={() => { setPage('Passage'); toggleMenu(); }}>Passage</li>
          <li style={{ textAlign: 'left', padding: '8px 0' }} onClick={() => { editionPassage();setPage('Liste'); toggleMenu(); }}>Liste des Passages</li>
          <hr />
          <li style={{ textAlign: 'left', padding: '8px 0' }} onClick={() => { deco(); toggleMenu(); }}>Déconnexion</li>       
          <hr />
          <li style={{ textAlign: 'left', padding: '8px 0' }} onClick={() => { setPage('Infos'); toggleMenu(); }}>Informations</li>
        </ul> */}
      {/* </div> */}
            </>
    );
  };

export default Infos;