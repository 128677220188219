import React from 'react';


// { toggleMenu, setPage }
const Menu = ({toggleMenu , setPage, deco, editionPassage}) => {


    return (
         <>
       {/* <h2>Menu</h2> */}
      <div style={{ width: '200px', backgroundColor: '#f0f0f0', height: '100%', position: 'fixed', top: 0, left: 0, zIndex:8, paddingLeft:5}}>
        <h2 onClick={()=>toggleMenu()}>Menu</h2>
        <hr />
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          {/* <li style={{ textAlign: 'left', padding: '8px 0' }} onClick={() => { setPage('Params'); toggleMenu(); }}>Paramètres</li> */}
          <li style={{ textAlign: 'left', padding: '8px 0' }} onClick={() => { setPage('Site'); toggleMenu(); }}>Sélection du site</li>
          <li style={{ textAlign: 'left', padding: '8px 0' }} onClick={() => { setPage('Passage'); toggleMenu(); }}>Passage</li>
          <li style={{ textAlign: 'left', padding: '8px 0' }} onClick={() => { setPage('Infos'); toggleMenu(); }}>Informations</li>
          <li style={{ textAlign: 'left', padding: '8px 0' }} onClick={() => { editionPassage();setPage('Liste'); toggleMenu(); }}>Liste des Passages</li>
          <hr />
          <li style={{ textAlign: 'left', padding: '8px 0' }} onClick={() => { deco(); toggleMenu(); }}>Déconnexion</li>
        </ul>
      </div>
            </>
    );
  };

export default Menu;