import React, { useState, useEffect} from 'react';
import './App.css';
import Login from './Login';
import Menu from './Menu.js';
import Passage from './Passage';
import Lecture from './Lecture';
// import Params2 from './Params2';
import Params from './Params';
import Liste from './Liste';
import MenuIcon from '@mui/icons-material/Menu';
import Infos from './Infos.js';
import Site from './Site.js';

// var api = 'http://localhost:3200' //'/api' //'http://192.168.1.69:3200'// 'http://localhost:3200';
//var api = '/api' //'http://192.168.1.69:3200'// 'http://localhost:3200';
const api = process.env.REACT_APP_API_URL;
console.log('api:', api);

function App() {
  const [state, setState] = useState({ isConnected: false, token: '', page: 'Passage', user:{}, numBadge:'', menuVisible: false });
  const [menuVisible, setMenuVisible] = useState(false);
  const [p, setP] = useState({});
  const [unités, setUnités] = useState({});
  const [matières, setMatières] = useState({});
  const [user, setUser] = useState({nom:'', prenom:''});
  const [users, setUsers] = useState({});
  const [sites, setSites] = useState({});
  const [site, setSite] = useState({code:'', lib:'', soc:''});
  const [os, setOs] = useState('');
  const [ua, setUa] = useState('');
  
// token en localStorage ?
useEffect(() => {
 
  let token = localStorage.getItem('token');
  if (token && token !== 'undefined') {
    let b = JSON.stringify({ token: token });
  console.log('b:', b);
    try {
      fetch(api + '/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: b
      })
        .then(response => {
          if (response.ok) {
            connexion(token);
          } else {
            localStorage.removeItem('token');
            setState({ ...state, isConnected: false, token: '', user: {} });
            setUsers({});
          }
        });
    } catch (error) {
      console.error('Erreur:', error);
    }
  }else{
    console.log('token : Pas de token ');
  }
  defSite();
}, []);

useEffect(() => {
  if (state.isConnected) {
    getSites();
    getUnités();
    getMatières();
    setP({});
    getUsers();
  }
}, [state.isConnected]);

  // console.log('toggleMenu App', menuVisible);

function defSite(s){
  // Lecture du site en localStorage
  let site = localStorage.getItem('site');
  if (site){
    let s = JSON.parse(atob(site));
    if (s){
      if (s.idSite){
        s.soc = "SMIRTOM"; 
      setSite(s);
    }else{
      setState({ ...state, page: 'Site' });
    }
 
}else{
  setSite({});
  setState({ ...state, page: 'Site' });
}

}else{
  setSite({});
  setState({ ...state, page: 'Site' });
}
}

function setPage(page) {
  if(page){
  setState({ ...state, page: page });
}
}

// Utilisation des données du token
function connexion(token) {
  if (token) {
    localStorage.setItem('token', token);
    console.log('Enregistrement state.token:', token);
    // Je veux lire le payload du token
    let payload = token.split('.')[1];
    let p = atob(payload);
    console.log('payload:', p);
    let pp = JSON.parse(p);
    setState({ ...state, isConnected: true, token: token, menuVisible: false, user: pp });
  }
}

  function setBadge(numBadge) {
    setState({ ...state, numBadge: numBadge });
  }

  function deconnexion() {
    localStorage.removeItem('token');
    setState({ ...state, isConnected: false, token: '', menuVisible: false, user: {}, numBadge: '', page: 'Site', site: {} });
    setUsers({});
  }

  function toggleMenu() {
    // console.log('toggleMenu', menuVisible);
    setMenuVisible(!menuVisible);
  }

  function setPage(page) {
    console.log('setPage:', page);
    setState({ ...state, page: page });
  }

  // console.log('state:', state);
function getSites(){
 // console.log('getSites', api + '/sites');
  fetch(api + '/sites', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + state.token,
    }
  })
      .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        console.error('Erreur de connexion');
      }
    }
    )
    .then(data => {
      console.log('Data:', data);
      setSites({data });
      return data;
    }
    )
    .catch(error => {
      console.error('Erreur:', error);
    }
    );
}

function getUnités(){
  console.log('getUnités');
  fetch(api + '/unites', {
    headers: {
      'Authorization': `Bearer ${state.token}`
    }
  })
  .then(response => {
    if (response.status === 401) {
      console.error('Unauthorized access - 401');
      // Gérer l'erreur 401 ici, par exemple en affichant un message ou en redirigeant l'utilisateur
      return;
    }
    return response.json();
  }
  )
  .then(data => {
    setUnités(data);
    console.log('data:', data);
}
)
}

function getUsers() {
  fetch(api + '/utilisateurs', {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
  })

      .then(response => {
          if (response.ok) {
              return response.json();
          } else {
              console.error('Erreur de connexion');
          }
      }
      )
      .then(data => {
          console.log('Data:', data);
          setUsers(data);
      }
  )
}

function getMatières(){
  console.log('getMatieres');
  let url = api + '/matieres';
  console.log('getMatieres url:', url);
  fetch(api + '/matieres', {
    headers: {
      'Authorization': `Bearer ${state.token}`
    }
  })
  .then(response => {
    if (response.status === 401) {
      console.error('Unauthorized access - 401');
      // Gérer l'erreur 401 ici, par exemple en affichant un message ou en redirigeant l'utilisateur
      return;
    } 
    return response.json();
  })
  .then(data => {
    setMatières(data);
    console.log('data:', data);
}
)
}

function editionPassage(p){
  console.log('editionPassage:', p);
  if(p){
    setP(p);
    setState({ ...state, page: 'Passage', numBadge:p.numBadge }); // On retourne sur la page de saisie
  }else{
    setP({});
  }
}

function getSiteName(idSite){
  let site = sites.data.find(s => s.idSite === idSite);
  return site.ville;
}


  return (
    <div className="App">
    {menuVisible && 
    <>
    <Menu toggleMenu={toggleMenu} setPage={setPage} deco={deconnexion} editionPassage={editionPassage}/>
    </>}
    {state.isConnected ? (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button onClick={toggleMenu} style={{ position: 'absolute', top: '10px', left: '10px' }}>
            <MenuIcon />
          </button>
        </div>
  {/* </> */}
        {state.page === 'Params' && (
          <Params
            token={state.token}
            users={users}
            user={user}
            sites={sites}
            setSite={setSite}
          />
        )}
        {/* {state.page === 'Params' && <Params token={state.token} user={state.user} users={users} />} */}
        {state.page === 'Lecture' && <Lecture />}
        {state.page === 'Site'    && <Site api={api} setPage={setPage} site={site} setSite={setSite}/>}
        {state.page === 'Infos'   && <Infos site={site} user={user} os={os} ua={ua} />}
        {state.page === 'Liste'   && <Liste api={api} getSiteName={getSiteName} editionPassage={editionPassage}/>}
        {state.page === 'Passage' && <Passage token={state.token} api={api} numBadge={state.numBadge} setBadge={setBadge} unités={unités} matières={matières} p={p} site={site}/>}
      </>
    ) : (
      <Login connexion={connexion} api={api} user={user} setUser={setUser} setOs={setOs} setUa={setUa} />
    )}
  </div>
);
}

export default App;