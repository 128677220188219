import React, { useState, useRef, useEffect} from 'react';
import { Input, TextField, Select, FormControl, InputLabel, MenuItem, Box, Button, Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

// { toggleMenu, setPage }
const Passage = ({token, user, numBadge, setBadge, api, p, unités, matières, site}) => {
  
  const [state, setState] = useState({numBadge:"", date:'', heure:'', nom:'', prenom:'', statut:'', ligne:0, saisieBadge:true, idPassage:0, version:1.1}); // Les données du badge
  const [apport, setApport] = useState({idMatiere:0, codeMatiere:'', libMatiere:'', idUnite:0, libUnite:'', codeUnite:'', quantite:0, commentaire:''}); // L'apport en cours
  const [tApport, setTApport] = useState([]); // Tableau des apports
  const [modif, setModif] = useState({titre:site.lib ,isModif:false}); // Tableau des apports
  const [abonné, setAbonné] = useState({}); // Abonné associé au badge
 
  // Référence pour le champ de badge
const badgeRef = useRef(null);
// Référence pour le champ de quantité
const quantiteRef = useRef(null);

  useEffect(() => {
    if (badgeRef.current) {
      badgeRef.current.focus();
    }
    if(numBadge && !state.numBadge){getBadge(numBadge);}
  }, []);


useEffect(() => {
  console.log('Passage useEffect 2');
  // Gestion de la transmission de l'objet passage
  if(p){
   
  console.log('----------------- Passage useEffect 2 p:', p);
   //getBadge(p.numBadge);
  // On repeuple tApport avec p.lines
  if(!p.lines){return}
  let t = [];
  p.lines.forEach(l => {
    let matiere = matières.find(m => m.idMatiere === l.idMatiere);
    let unite = unités.find(u => u.idUnite === matiere.IDUnite);

 console.log('ligne:', l)
// console.log('unite:', unite);if(!unite){return;}

    t.push({ idMatiere: l.idMatiere, libMatiere: matiere.lib_matiere, idUnite: matiere.IDUnite, libUnite: unite.lib_unite, codeUnite: unite.code_unite, quantite: l.qteDechet });
  });
  console.log('t:', t);
  setTApport(t);
  setModif({ titre:'Modification de passage en quai', isModif:true});
  }
}, [p]);

  
function getAbonné(id){
  console.log('getAbonné');
  if(!id){return;}
  let url = api + '/abonne?id=' + id;
  fetch(url, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  .then(response => {
    if (response.status === 401) {
      console.error('Unauthorized access - 401');
      // Gérer l'erreur 401 ici, par exemple en affichant un message ou en redirigeant l'utilisateur
      return;
    } 
    return response.json();
  })
  .then(data => {
    console.log('Abonné :', data);
    setAbonné(data);
}
)
}


function handleChange(e){
 if(e.target.id === 'badge'){
  let numBadge = e.target.value;
   if(numBadge.length < 7){
     setState({ ...state, numBadge: numBadge });
    }
    if(numBadge.length === 6){
     getBadge(numBadge);
    }
}

//console.log('e.target.id:', e.target);
  if(e.target.name === 'matieres' && e.target.value >  0){
    let matiere = matières.find(m=>m.idMatiere === e.target.value);
    let unite = unités.find(u=>u.idUnite === matiere.IDUnite);
    setApport({...apport, idMatiere:matiere.idMatiere, libMatiere:matiere.lib_matiere, idUnite:matiere.IDUnite, libUnite:unite.lib_unite, codeUnite:unite.code_unite, quantite:0}); 
    setTimeout(() => quantiteRef.current.focus(), 0);
   }
  if(e.target.id === 'qte'){
    let transformedValue = e.target.value.replace(',', '.');
    if (/^0[1-9]/.test(transformedValue)) {
      transformedValue = transformedValue.replace(/^0+/, '');
    }
    setApport(prevApport => ({ ...prevApport, quantite: transformedValue }));
  }
}

function addApport() {
  if(apport.quantite === 0){return;}
  if(apport.idMatiere === 0){return;}
  // Check if the tApport array already contains a line with the same matiere
  const existingLine = tApport.find(a => a.idMatiere === apport.idMatiere);
 // console.log('existingLine:', existingLine);
  if (existingLine) {
    let q = Number(apport.quantite) + Number(existingLine.quantite);
    // If an existing line is found, update the quantity
    console.log('Addition des quantités dans ce cas:', q);
    q = Number(q.toFixed(2));
    const updatedApport = { ...existingLine, quantite: q };
    const updatedTApport = tApport.map(a => (a.idMatiere === apport.idMatiere ? updatedApport : a));
    console.log('updatedTApport:', updatedTApport);
    setTApport(updatedTApport);
  } else {
    // If no existing line is found, add the apport to the tApport array
    let app = apport;
    app.quantite = Number(apport.quantite);
    const updatedTApport = [...tApport, apport];
    setTApport(updatedTApport);
  }
 // console.log('tApport:', tApport);
}

function getBadge(numBadge){
  if(!numBadge){return;}
  console.log('getBadge');
  fetch(api + '/badge?num_badge='+numBadge, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  .then(response => {
    if (response.status === 401) {
      console.error('Unauthorized access - 401');
      // Gérer l'erreur 401 ici, par exemple en affichant un message ou en redirigeant l'utilisateur
      return;
    }
    return response.json();
  })
  .then(data => {
    //console.log('data:', data);
    let nom = '' ;
    let prenom = '' ;
    let statut = '' ;
    statut = data.statut_badge;
    if(data.nom){
      nom = data.nom;
      prenom = data.prenom;
    } 
    if(!data.nom&&data.nom2){
      nom = data.nom2;
      prenom = data.prenom2;
    }  
    if(!data.nom&&!data.nom2&&data.nom3){
      nom = data.nom3;
      prenom = data.prenom3;
    } 
console.log(data);
    getAbonné(data.IDAbonne)

    if(data.statut_badge==='ACTIF'){setBadge(numBadge);}
    setState({...state, date:data.date, heure:data.heure, statut:statut, nom:nom, prenom:prenom, numBadge:numBadge, saisieBadge:false, idAbonne:data.IDAbonne, idBAdge:data.IDBadge});
    setApport({...apport, idMatiere:0, codeMatiere:'', libMatiere:'', idUnite:0, libUnite:'', codeUnite:'', quantite:0, commentaire:''});
   // setTApport([]);
})
}

// Fonction pour supprimer une ligne d'apport
function removeApport(idMatiere) {
  const updatedTApport = tApport.filter(apport => apport.idMatiere !== idMatiere);
  setTApport(updatedTApport);
}

function savePassage(){
  console.log('savePassage');
  if(!tApport.length){return;}
  let id = 0
  if(modif.isModif){
    id = p.idPassage;
  }

  let passage = {d1:new Date() , numBadge:state.numBadge, idPassage:id , idAbonne:state.idAbonne, idBadge:state.idBadge, apports:tApport.map(a=>{return {idMatiere:a.idMatiere, quantite:a.quantite, codeMatiere:a.codeMatiere,}})};
  console.log('passage:', passage);

  fetch(api + '/passage', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(passage)
  })
  .then(response => {
    console.log('response:', response);
    if (response.status === 400) {
      console.error('Demande mal interprétée- 400');
      // Gérer l'erreur 401 ici, par exemple en affichant un message ou en redirigeant l'utilisateur
      return;
    }
    if (response.status === 401) {
      console.error('Unauthorized access - 401');
      // Gérer l'erreur 401 ici, par exemple en affichant un message ou en redirigeant l'utilisateur
      return;
    }
    if (response.status === 200) {
console.log('Passage enregistré', site);

      setBadge('');
      setState({numBadge:'', date:'', heure:'', nom:'', prenom:'', statut:'', ligne:0, saisieBadge:true});
      setTApport([]);
      setAbonné({});
      setModif({ titre:site.lib, isModif:false});

      //document.getElementById('badge').focus();
      //console.error('Unauthorized access - 401');
      // Gérer l'erreur 401 ici, par exemple en affichant un message ou en redirigeant l'utilisateur
      setTimeout(() => {
        const saisie = document.getElementById('badge');
        if (saisie) {
          saisie.focus();
        }
        // if (badgeRef.current) {
        //   badgeRef.current.focus();
        // }
      }, 200);

      return;
    }
    return response.json();
  })
}

    return (
      <>
      <h2 style={{ textAlign: 'center' }}><span style={{color:'green'}}>Gecad - </span>{modif.titre}</h2>
      {state.saisieBadge &&

      <TextField
        id='badge'
        label='Numéro de badge'
        onChange={e => handleChange(e)}
        value={state.numBadge}
        inputProps={{
        pattern: "^[0-9]{0,6}$"
        }}
        inputRef={badgeRef}
      />
      }


      <br />
       {/* &nbsp;{state.date} &nbsp;{state.heure} &nbsp; */}
       {/* {!state.nom && abonné.raison_sociale && <p>Abonné : {abonné.raison_sociale}</p>}
       {state.nom && abonné.raison_sociale && <p>Abonné : {abonné.raison_sociale}</p>} */}

       {abonné.raison_sociale} &nbsp;{state.statut && "Badge : " + state.numBadge + " " + state.statut}
      <br />
      
      <br />
      {!state.saisieBadge && !modif.isModif &&<Button variant="contained" color="secondary" onClick={() => {
        setState({...state, numBadge:'', saisieBadge:true, nom:'',prenom:'', statut:''})
      setTimeout(() => {
        const saisie = document.getElementById('badge');
        if (saisie) {
        saisie.focus();
        }
      }, 200);
      }
        }>Saisir un autre badge</Button>}

  {modif.isModif &&<Button variant="contained" color="secondary" onClick={() => {
        
        setState({...state, numBadge:'', saisieBadge:true, nom:'',prenom:'', statut:'', p:null})
        setModif({ titre:'Saisie de passage', isModif:false});
        setAbonné({});
      setTimeout(() => {
        const saisie = document.getElementById('badge');
        if (saisie) {
        saisie.focus();
        }
      }, 200);
      }
        }>Annuler</Button>}

        {/* {abonné.raison_sociale && <p>Abonné : {abonné.raison_sociale}</p>}   */}

      <br />
      {state.statut === 'ACTIF' && !state.saisieBadge &&
      <>
      <hr></hr>

   <FormControl sx={{ m: 1, minWidth: '60%' }}>
      {/* <InputLabel id="lMatieres">Matiere 88</InputLabel> */}
      <Autocomplete
        id="matieres"
        options={matières}
        getOptionLabel={(option) => option.lib_matiere}
        renderInput={(params) => <TextField {...params} label="Matiere" />}
        onChange={(event, newValue) => {
        if (newValue) {
          setApport(prevApport => {
          const unite = unités.find(u => u.idUnite === newValue.IDUnite);
          return {
            ...prevApport,
            idMatiere: newValue.idMatiere,
            libMatiere: newValue.lib_matiere,
            idUnite: newValue.IDUnite,
            libUnite: unite ? unite.lib_unite : '',
            codeUnite: unite ? unite.code_unite : ''
          };
          });
           // Donner le focus à l'input de saisie de la quantité
           setTimeout(() => quantiteRef.current.focus(), 0);
        }
        }}
      />
      </FormControl>

      <Box
        component="form"
        sx={{ '& > :not(style)': { m: 1, width: '10ch' } }}
        noValidate
        autoComplete="off"
      >
       
       <TextField
        id="qte"
        label="Quantite"
        variant="outlined"
        onChange={(e => handleChange(e))}
        inputProps={{
        step: "0.01",
        min: "0",
        max: "9.99",
        pattern: "^[0-9](\\.[0-9]{1,2})?$"
        }}
        value={apport.quantite}
        inputRef={quantiteRef}
        style={{ backgroundColor: apport.quantite === 0 ? '#FFFFE0' : 'white' }}
      />

        <TextField id="u" label="Unité" variant="outlined" value={apport.codeUnite} />

        <Button variant="contained" onClick={addApport}>Ajout</Button>

      </Box>
      {tApport &&
      <>
      <TableContainer style={{marginBottom:10}}>
        <Table>
        <TableHead>
          <TableRow>
          <TableCell>Matière</TableCell>
          <TableCell>Quantité</TableCell>
          <TableCell>Unité</TableCell>
          <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tApport.map((apport, index) => (
          <TableRow key={index} onClick={() => setState({ ...state, ligne: index })}>
            <TableCell>{apport.libMatiere}</TableCell>
            <TableCell align="center" sx={{ width: '50px' }}>{apport.quantite}</TableCell>
            <TableCell align="center" sx={{ width: '50px' }}>{apport.codeUnite}</TableCell>
            <TableCell align="center" sx={{ width: '50px' }}>
            <Button
            variant="contained"
            color="secondary"
            onClick={() => removeApport(apport.idMatiere)}
            sx={{ minWidth: '30px', padding: '5px' }}
            >
            -
            </Button>
          </TableCell>
          </TableRow>
          ))}
        </TableBody>
        </Table>
      </TableContainer>
      {/* <hr></hr> */}
      <Button variant="contained" color="secondary" onClick={() => savePassage()}>Enregistrer le passage</Button>
      </>}
      </>}
      </>
      
    );
  };

export default Passage;