import React, { useState, useEffect} from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';

const Login = ({ connexion, api, token, user, setUser, setOs, setUa }) => {
  // const [user, setUser] = useState({ email:'', password:'', error:false, token:token, isConnected:false });

// On teste si l'utilisateur est déjà connecté, et si son token est valide

/*
  Le plus court chemon pour la connexion : vérifier si le token est valide
*/
  useEffect(() => {
    // Token déjà présent valide ?
    if (token) {
      try {
        console.log('api:', api+"/check");
        fetch(api + '/check', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
        }).then(response => {
          console.log("response:", response);
          if (response.ok) {
            setUser({...user,  isConnected:true, error:false, nom:response.nom, prenom:response.prenom});
          } else {
            setUser({...user,  error:true});
          }
        })
      } catch (error) {
        console.error('Erreur:', error);
      }
    }else{
      console.log('Absence de token');
    }
    setOs(getOSVersion());
    setUa(getBrowserVersion());
  }, []);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    // On sauve le token dans le localStorage
    //localStorage.setItem('token', user.token);
    try {
      const response = await fetch(api + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user:user.email, psw:user.password }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('data /login', data);
        setUser({ isConnected:true, token:data.token, error:false, nom:data.nom, prenom:data.prenom });
        connexion(data.token);
      } else {
        console.error('Erreur de connexion', "/login", response.status);
        setUser({isConnected:false,error:true, token:'', nom:'', prenom:''});
      }
    } catch (error) {
      console.error('Erreur:', error);
    }
  };

  function getOSVersion() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    // Windows
    if (/Windows NT 10.0/.test(userAgent)) return "Windows 10";
    if (/Windows NT 6.3/.test(userAgent)) return "Windows 8.1";
    if (/Windows NT 6.2/.test(userAgent)) return "Windows 8";
    if (/Windows NT 6.1/.test(userAgent)) return "Windows 7";
    if (/Windows NT 6.0/.test(userAgent)) return "Windows Vista";
    if (/Windows NT 5.1/.test(userAgent)) return "Windows XP";
  
    // MacOS
    if (/Mac OS X (\d+[\._]\d+)/.test(userAgent)) {
      return `MacOS ${userAgent.match(/Mac OS X (\d+[\._]\d+)/)[1].replace('_', '.')}`;
    }
    // iOS
    if (/iPhone OS (\d+[\._]\d+)/.test(userAgent)) {
      return `iOS ${userAgent.match(/iPhone OS (\d+[\._]\d+)/)[1].replace('_', '.')}`;
    }
    if (/iPad; CPU OS (\d+[\._]\d+)/.test(userAgent)) {
      return `iOS ${userAgent.match(/iPad; CPU OS (\d+[\._]\d+)/)[1].replace('_', '.')}`;
    }
    // Android
    if (/Android (\d+[\.\d]+)/.test(userAgent)) {
      return `Android ${userAgent.match(/Android (\d+[\.\d]+)/)[1]}`;
    }
    // Linux (pas de version précise dans le userAgent généralement)
    if (/Linux/.test(userAgent)) {
      return "Linux (version non détectable)";
    }
    return "Version inconnue";
  }

  function getBrowserVersion() {
    const userAgent = navigator.userAgent;
    let browserName, fullVersion;
  
    // Détecter Chrome
    if (/Chrome\/(\d+\.\d+)/.test(userAgent) && !/Edg/.test(userAgent)) {
      browserName = "Chrome";
      fullVersion = userAgent.match(/Chrome\/(\d+\.\d+)/)[1];
    }
    // Détecter Firefox
    else if (/Firefox\/(\d+\.\d+)/.test(userAgent)) {
      browserName = "Firefox";
      fullVersion = userAgent.match(/Firefox\/(\d+\.\d+)/)[1];
    }
    // Détecter Edge
    else if (/Edg\/(\d+\.\d+)/.test(userAgent)) {
      browserName = "Edge";
      fullVersion = userAgent.match(/Edg\/(\d+\.\d+)/)[1];
    }
    // Détecter Safari
    else if (/Safari\/(\d+\.\d+)/.test(userAgent) && !/Chrome/.test(userAgent)) {
      browserName = "Safari";
      if (/Version\/(\d+\.\d+)/.test(userAgent)) {
        fullVersion = userAgent.match(/Version\/(\d+\.\d+)/)[1];
      }
    }
    // Détecter Internet Explorer
    else if (/MSIE (\d+\.\d+);/.test(userAgent)) {
      browserName = "Internet Explorer";
      fullVersion = userAgent.match(/MSIE (\d+\.\d+);/)[1];
    }
    // Détecter IE 11
    else if (/rv:(\d+\.\d+)/.test(userAgent) && /Trident/.test(userAgent)) {
      browserName = "Internet Explorer";
      fullVersion = userAgent.match(/rv:(\d+\.\d+)/)[1];
    }
    // Si le navigateur n'est pas détecté
    else {
      browserName = "Navigateur inconnu";
      fullVersion = "Version inconnue";
    }
  
    return `${browserName} version ${fullVersion}`;
  }
  
  // console.log(getBrowserVersion());
  // console.log(`Version de l'OS : ${getOSVersion()}`);


  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="5vh"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          <h2><span style={{color:'green'}}>Gecad</span> </h2>
        </Typography>
        <form onSubmit={handleSubmit} >
          <div style={{display:'flex', justifyContent:'space-between'}}>
            <TextField
              label="Code utilisateur"
              type="email"
              value={user.email}
              onChange={(e) => setUser({...user,  email:e.target.value})}
              required
              // fullWidth
              margin="normal"
            />
            <TextField
              label="Mot de passe"
              type="password"
              value={user.password}
              onChange={(e) => setUser({...user, password:e.target.value})}
              required
              // fullWidth
              margin="normal"
              style={{marginLeft:'10px'}}
            />
          </div>
          {user.error && <Typography color="error">Les informations fournies ne conviennent pas</Typography>}
          <Button type="submit" onClick={handleSubmit} variant="contained" color="primary" fullWidth>
            Login
          </Button>
        </form>
      </Box>
      <Typography variant="body2" align="center">
      <p style={{fontSize:'small', color:'gray'}}>
        * vos informations sont disponibles auprès de votre administrateur
        </p> 
      </Typography>
    </Container>
  );
};

export default Login;